<template>
    <ChargemapMenuSection
        v-for="section in menuSections"
        :key="section.id"
        data-testid="menu-user-section"
        :list-links="section.links"
        :isBig
        :authorized="section.authorized"
    />
</template>

<script lang="ts">
import { mapState } from 'pinia'
import usePartnersStore from '~/store/partners'
import useNetworksStore from '~/store/networks'
import useSessionStore from '~/store/session'
import type { Menu } from '~/interfaces/menu'
import useEngagementsStore from '~/store/engagements'

export default {
    name: 'MenuUserSection',
    props: {
        isBig: {
            type: Boolean,
            default: true
        }
    },
    emits: ['selected', 'closeMobileMenu'],
    computed: {
        ...mapState(usePartnersStore, ['currentPartner']),
        ...mapState(useSessionStore, ['isSuperAdmin']),
        ...mapState(useNetworksStore, [
            'currentNetwork',
            'currentCommunityNetwork'
        ]),
        menuSections(): Menu[] {
            const sections: Menu[] = [
                {
                    id: 'partner-pages',
                    links: [
                        {
                            id: 'menu-partners',
                            title: this.$t('appMenu.admin.partners'),
                            icon: 'IconChargemapPartners',
                            url: `/partners`,
                            authorized: this.isSuperAdmin
                        }
                    ],
                    authorized: this.isSuperAdmin
                }
            ]

            if (this.currentCommunityNetwork || this.currentNetwork) {
                sections.push({
                    id: 'networks-pages',
                    links: [
                        {
                            id: 'menu-data',
                            title: this.$t('appMenu.user.data'),
                            icon: 'IconRectangleGroup',
                            url: `${this.networkLink}/data`,
                            authorized: true
                        },
                        {
                            id: 'menu-stations',
                            title: this.$t('appMenu.user.stations'),
                            icon: 'IconChargingStation',
                            url: `${this.networkLink}/stations`,
                            authorized: true
                        },
                        {
                            id: 'menu-inbox',
                            title: this.$t('appMenu.user.inbox'),
                            icon: 'IconInboxArrowDown',
                            count: useEngagementsStore().unreadFeedbackCount,
                            url: `${this.networkLink}/inbox?type=WITH_COMMENT%7CUNREAD`,
                            authorized: true
                        }
                    ],
                    authorized: true
                })

                sections.push({
                    id: 'parameters',
                    links: [
                        {
                            id: 'menu-partner-parameters',
                            title: this.$t('appMenu.user.parametersPartner'),
                            icon: 'IconCog',
                            url: `${this.partnerLink}/parameters`,
                            authorized: true
                        }
                    ],
                    authorized: true
                })
            }

            return sections
        },
        networkLink(): string {
            let url = `/partners/${this.currentPartner?.id}`

            if (this.currentNetwork) {
                url += `/networks/interop/${this.currentNetwork.id}`
            } else if (this.currentCommunityNetwork) {
                url += `/networks/community/${this.currentCommunityNetwork.id}`
            }

            return url
        },
        partnerLink(): string {
            return `/partners/${this.currentPartner?.id}`
        }
    }
}
</script>
